import React from "react"
import blackrock from "../../images/sponsors/BlackRock.png"
import fastforward from "../../images/sponsors/FastForward.png"
import github from "../../images/sponsors/GitHub.png"
import google from "../../images/sponsors/Google.png"
import pagerduty from "../../images/sponsors/PagerDuty.png"
//import thomsonreuters from "../../images/sponsors/thomsonreuters.png"
import berkeley from "../../images/sponsors/UC Berkeley.png"
import mit from "../../images/sponsors/MIT.png"
import camelback from "../../images/sponsors/Camelback Ventures.png"
import roddenberry from "../../images/sponsors/Roddenberry Foundation.png"
import usf from "../../images/sponsors/UC San Francisco.png"
import drk from "../../images/sponsors/DRK Foundation.png"
import twilio from "../../images/sponsors/Twilio.png"
import stv from "../../images/sponsors/Stand Together Ventures.png"
import CAEndow from "../../images/sponsors/The California Endowment.png"
import CarlMarie from "../../images/sponsors/Carl C. Anderson Foundation.png"
import Dartmouth from "../../images/sponsors/Dartmouth.png"
import Standford from "../../images/sponsors/Stanford University.png"
import Harvard from "../../images/sponsors/Harvard Uniersity.png"
import EchoingGreen from "../../images/sponsors/Echoing Green.png"
import JMKaplanFund from "../../images/sponsors/J.M. Kaplan Fund.png"
import OrliFoundation from "../../images/sponsors/Orli Foundation.png"
import pkg from "../../images/sponsors/PKG.png"
// import sahajai from "../../images/sponsors/sahajai.png"


import "./supporters.css"

// TODO: Rearrange
const Supporters = () => (
  <div className="container supporters-container">
    <h1> Our supporters </h1>
    <div className="supporters-titleText">
      {" "}
      Thanks to our funders and individual donors, accesSOS is completely free
      for all to use, 24/7.{" "}
    </div>
    <div className="supporters-sponsorContainer">
      <div>
        {" "}
        <img src={drk} alt="Draper Richards Kaplan Foundation" />{" "}
      </div>
      <div>
        {" "}
        <img src={stv} alt="Stand Together Ventures" />{" "}
      </div>
      <div>
        {" "}
        <img src={pagerduty} alt="Pager Duty" />{" "}
      </div>
      <div>
        {" "}
        <img style={{transform:'scale(0.7)'}}  src={EchoingGreen} alt="Echoing Green" />{" "}
      </div>
      <div>
        {" "}
        <img src={fastforward} alt="Fast Forward" />{" "}
      </div>
      <div>
        {" "}
        <img src={twilio} alt="Twilio" />{" "}
      </div>
      <div>
        {" "}
        <img src={roddenberry} alt="Roddenberry Foundation" />{" "}
      </div>
      <div>
        {" "}
        <img src={camelback} alt="Camelback Ventures" />{" "}
      </div>
      <div>
        {" "}
        <img src={blackrock} alt="Black Rock" />{" "}
      </div>
      <div>
        {" "}
        <img src={google} alt="Google" />{" "}
      </div>
      <div>
        {" "}
        <img src={CAEndow} alt="The California Endowment" />{" "}
      </div>
      <div>
        {" "}
        <img src={CarlMarie} alt="Carl and Marie Anderson Charitable Foundation"
        />{" "}
      </div>
      {/* <div>
        {" "}
        <img src={OrliFoundation} alt="Orli Foundation" />{" "}
      </div> */}
      <div>
        {" "}
        <img src={github} alt="Github" />{" "}
      </div>
      <div>
        {" "}
        <img src={JMKaplanFund} alt="J.M Kaplan Fund" />{" "}
      </div>
      {/* <div>
        {" "}
        <img src={pkg} alt="Priscilla King Gray" />{" "}
      </div> */}
      <div>
        {" "}
        <img src={berkeley} alt="UC Berkeley School of Information" />{" "}
      </div>
      {/* <div>
        {" "}
        <img src={sahajai} alt="Sahaj AI" />{" "}
      </div> */}
      <div>
        {" "}
        <img src={usf} alt="University of San Francisco" />{" "}
      </div>
      <div>
        {" "}
        <img src={Dartmouth} alt="Dartmouth" />{" "}
      </div>
      <div>
        {" "}
        <img src={mit} alt="Massachusetts Institute of Technology" />{" "}
      </div>
      <div>
        {" "}
        <img src={Harvard} alt="Harvard" />{" "}
      </div>
      <div>
        {" "}
        <img src={Standford} alt="Stanford" />{" "}
      </div>
     
    
     
   
      
    </div>
  </div>
)

export default Supporters
